import React from "react"
import {BlogContainer, SubTitleSection,Text } from "../../components/atomics"
import {Link} from "gatsby";
import Page from "../../components/Pages/Page";

const Ebooks = () => {
  const ebooks = [
    {
      url: '/guia-definitivo-para-a-produtividade/?utm_source=website&utm_medium=ebooks&utm_campaign=ebook',
      title: 'Guia definitivo para a produtividade',
      description:'23 técnicas (+6 BÔNUS) para ser mais produtivo e alcançar seus objetivos!',
      img: require('../../assets/images/ebookProdutividade.png')
    },
    {
      url: '/metas-e-planejamento/?utm_source=website&utm_medium=ebooks&utm_campaign=ebook',
      title: 'Metas e Planejamento',
      description:'6 passos essenciais para criar metas + 6 tipos de planejamentos + 3 bônus EXCLUSIVOS em PDF!',
      img: require('../../assets/images/ebookMetas.png')
    },
    {
      url: '/guia-completo-habitos/?utm_source=website&utm_medium=ebooks&utm_campaign=ebook',
      title: 'Guia completo sobre hábitos',
      description:'10 táticas (+2 BÔNUS) incríveis para criar e manter hábitos no longo prazo!',
      img: require('../../assets/images/ebookHabitos.png')
    },
    {
      url: '/combo-ebooks-produtividade-habitos-metas/?utm_source=website&utm_medium=ebooks&utm_campaign=ebook',
      title: 'COMBO: Ebooks Produtividade + Hábitos + Metas e Planejamentos',
      description:'Tenha em mãos todas as ferramentas para alcançar sua melhor versão!',
      img: require('../../assets/images/combo.png')
    },
    // {
    //   url: '/guia-completo-habitos-e-metas',
    //   title: 'COMBO: Guia completo sobre hábitos + Metas e Planejamento',
    //   description:'2 Ebooks: Guia completo sobre hábitos + Metas e Planejamento',
    //   img: require('../../assets/images/ebookCombo.png')
    // },
  ];
  const description="Os melhores ebooks sobre produtividade, hábitos, metas e planejamento para você colocar em prática, ser mais produtivo e atingir seus objetivos!"
  
  return(
  <Page title={'Ebooks'} description={description} pageId="ebooks" imageUrl={require('../../assets/images/combo.png')}>
    <BlogContainer>
      <Text className="text-description">{description}</Text>
      <div className="border"/>
      
      <div className="posts">
      {
        ebooks.map(
          post=> (
            <Link to={post.url} className="post">
              <div>
                <img src={post.img} style={{height:300, marginBottom:10}} alt={post.title} />
                <SubTitleSection className="title-blog">
                  {post.title}
                </SubTitleSection>
                <Text className="text-description shadow" style={{marginTop:5}}>
                  {post.description}
                </Text>
              </div>
            </Link>
          ))
        }
      </div>
    </BlogContainer>
    <br/>
    <br/>
  </Page>
)}

export default Ebooks
